import {ValidationRules} from './../models/validation';

// Those functions can be overrided for each theme
// You can define wich validation you want to apply to your themes

export const messagesErrorSuffix = {
    NOT_EMPTY: 'Message',
    MIN_LENGTH: 'MessageLength',
    ONE_LOWERCASE_CHAR: 'MessageLowercase',
    ONE_UPPERCASE_CHAR: 'MessageUppercase',
    ONE_DIGIT: 'MessageDigit',
    ONE_SPECIAL_CHAR: 'MessageSpecial',
    ALREADY_USED: 'AlreadyUsedMessage'
};

export const validationRules: ValidationRules = {
    lowercaseRegex: new RegExp(/^(?=.*?[a-z]).*$/),
    uppercaseRegex: new RegExp(/^(?=.*?[A-Z]).*$/),
    digitRegex: new RegExp(/^(?=.*?[0-9]).*$/),
    specialCharacterRegex: new RegExp(/^(?=.*?\W).*$/),
    emailRegex: new RegExp(/^([a-zA-Z0-9_\+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/),
    phoneNumberRegex: new RegExp(/^0[0-9]{9}$/),
    clientIdentifierRegex: new RegExp(/^[0-9]{8}$/),
    optCodeRegex: new RegExp(/^[0-9]{6}$/),

    checkEmail: function (input: HTMLInputElement) {
        return [{
            showError: !this.emailRegex.test(input.value),
            messageSuffix: messagesErrorSuffix.NOT_EMPTY
        }];
    },

    checkPreviousValue: function (input: HTMLInputElement) {
        return [{
            showError: input.value == input.getAttribute('previousValue'),
            messageSuffix: messagesErrorSuffix.ALREADY_USED
        }];
    },

    checkPassword: function (input: HTMLInputElement) {
        return [{
            showError: !input.value || input.value.length < 8, // Password must be at least 8 long
            messageSuffix: messagesErrorSuffix.MIN_LENGTH
        }];
    },

    checkCalculatorPassword: function (input: HTMLInputElement) {
        return [{
            showError: !input.value || input.value.length < 6, // Password must be at least 6 long
            messageSuffix: messagesErrorSuffix.MIN_LENGTH
        }];
    },

    checkPhoneNumber: function (input: HTMLInputElement) {
        return [{
            showError: !this.phoneNumberRegex.test(input.value),
            messageSuffix: messagesErrorSuffix.NOT_EMPTY
        }];
    },

    checkClientIdentifier: function (input: HTMLInputElement) {
        return [{
            showError: !this.clientIdentifierRegex.test(input.value),
            messageSuffix: messagesErrorSuffix.NOT_EMPTY
        }];
    },

    checkNewPassword: function (input: HTMLInputElement) {
        // Init all messages to false to prevent displaying messages if no value
        let messages = [
            {showError: true, messageSuffix: messagesErrorSuffix.NOT_EMPTY},
            {showError: false, messageSuffix: messagesErrorSuffix.MIN_LENGTH},
            {showError: false, messageSuffix: messagesErrorSuffix.ONE_LOWERCASE_CHAR},
            {showError: false, messageSuffix: messagesErrorSuffix.ONE_UPPERCASE_CHAR},
            {showError: false, messageSuffix: messagesErrorSuffix.ONE_DIGIT},
            {showError: false, messageSuffix: messagesErrorSuffix.ONE_SPECIAL_CHAR},
        ];
        let value = input.value;

        if (value) {
            messages = [
                //if value, it not empty
                {showError: false, messageSuffix: messagesErrorSuffix.NOT_EMPTY},
                // Password must be at least 12 long
                {showError: value.length < 12, messageSuffix: messagesErrorSuffix.MIN_LENGTH},
                // Must contains at least one lowercase
                {showError: !this.lowercaseRegex.test(value), messageSuffix: messagesErrorSuffix.ONE_LOWERCASE_CHAR},
                // Must contains at least one uppercase
                {showError: !this.uppercaseRegex.test(value), messageSuffix: messagesErrorSuffix.ONE_UPPERCASE_CHAR},
                // Must contains at least one digit
                {showError: !this.digitRegex.test(value), messageSuffix: messagesErrorSuffix.ONE_DIGIT},
                // Must contains at least one special character
                {
                    showError: !this.specialCharacterRegex.test(value),
                    messageSuffix: messagesErrorSuffix.ONE_SPECIAL_CHAR
                },
            ]
        }

        return messages;
    },

    checkOTPCode: function (input: HTMLInputElement) {

        return [{
            showError: !this.optCodeRegex.test(input.value),
            messageSuffix: messagesErrorSuffix.NOT_EMPTY
        }];
    }

};
